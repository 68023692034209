<template>
  <div class="flex gap-6 text-2xl xxs:text-3xl">
    <a href="https://www.instagram.com/yorkunisu" target="_blank"
      ><i class="fa-brands fa-instagram" />
      <p class="sr-only">York SU Instagram</p>
    </a>
    <a href="https://www.facebook.com/yorkunisu" target="_blank"
      ><i class="fa-brands fa-facebook" />
      <p class="sr-only">York SU Facebook</p>
    </a>
    <a href="https://tiktok.com/@yorkunisu" target="_blank"
      ><i class="fa-brands fa-tiktok" />
      <p class="sr-only">York SU TikTok</p>
    </a>
    <a href="http://www.youtube.com/@ThisIsRosesLive" target="_blank"
      ><i class="fa-brands fa-youtube" />
      <p class="sr-only">Roses Live YouTube</p>
    </a>
    <a href="https://github.com/YUSU-Dev/roses-live" target="_blank"
      ><i class="fa-brands fa-github"></i>
      <p class="sr-only">York SU Roses Live Github</p>
    </a>
  </div>
</template>

<script>
export default {
  name: "SocialLinks",
};
</script>
